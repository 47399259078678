import React from 'react'
import DefaultLayout from '../components/layout'
import SEO from '../components/seo'

class NotFoundPage extends React.Component {
  render() {
    const { data } = this.props

    return (
      <DefaultLayout>
        <SEO title="404: Not Found" />
        <div className="clearfix post-content-box">
          <div className="clearfix inline-headers">
            <h4 className="h4smaller">Максим&nbsp;Гурбатов&nbsp;<em>и</em> Анна&nbsp;Чайковская</h4>
            <h3><a href="/">Книга Букв</a></h3>
            <h4><a href="/contents/"><em>самый первый литерный проект</em></a></h4>
          </div>
          <article className="article-page fourofour">
            <div class="verticalcentre">
          <h1>404: Страница не найдена.</h1>
          <p class="bigger">Попробуйте заглянуть в <a href="/contents/">оглавление</a>.
          </p>
          </div>
          </article>
        </div>
      </DefaultLayout>
    )
  }
}

export default NotFoundPage
